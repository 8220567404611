.contacto-section{
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
}
.form-msg-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.input-row{
    display: flex;
    gap: 30px;
}


.txtarea-form{
    min-width: 100%;
    width: 100%;
    max-width: 100%;
    min-height: 150px;
}

@media screen and (max-width: 600px) {
    .input-row{
        flex-direction: column;
    }
    
}