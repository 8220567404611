.center-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  min-width: 40%;
}
.center-nav a {
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  gap: 5px;
}

.center-nav img {
  width: 20px;
}
