
/* html::-webkit-scrollbar{
    overflow: scroll;
    display: none;
} */
body{
    font-family: 'Poppins', sans-serif;
}

:root{
    --full-height: calc(100vh - 230px);
    --color-light-gray: rgba(151, 151, 151, 1);
}

a{
    text-decoration: none;
    color: white;
}

.swal2-container {
    z-index: 100000;
  }

.container-section {
    padding: 30px 20px;
}